import React, { useEffect, useState } from 'react';
import api from './Axios/api';

const TenderItemForm = () => {
    const imgURl = process.env.REACT_APP_API_IMG;
    const [items, setItems] = useState([]);
    const [form, setForm] = useState({
        item_name: '',
        item_rate: '',
        description: '',
        item_quantity: '',
        item_image: []
    });
    const [errors, setErrors] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setForm({
            ...form,
            item_image: files
        });
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.item_name = form.item_name ? "" : "Item Name is required.";
        tempErrors.item_rate = form.item_rate ? "" : "Item Rate is required.";
        tempErrors.description = form.description ? "" : "Description is required.";
        tempErrors.item_quantity = form.item_quantity ? "" : "Item Quantity is required.";
        tempErrors.item_image = form.item_image.length ? "" : "At least one image is required.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;

        const formData = new FormData();
        formData.append('item_name', form.item_name);
        formData.append('item_rate', form.item_rate);
        formData.append('description', form.description);
        formData.append('item_quantity', form.item_quantity);

        form.item_image.forEach((image, index) => {
            formData.append('item_image', image);
        });

        try {
            const res = await api.post('tender-item/add', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setItems([...items, res.data]);
            setForm({ item_name: '', item_rate: '', description: '', item_quantity: '', item_image: [] });
            setErrors({});
            getTenderList();
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await api.delete(`tender-item/delete/${id}`);
            getTenderList();
        } catch (error) {
            console.log(error);
        }
    };

    const getTenderList = async () => {
        try {
            const res = await api.get('tender-item/get');
            setItems(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleFinalSubmit = async () => {
        if (items.length > 0) {
            console.log('Submitting all items:', items);
        } else {
            alert('No items to submit.');
        }
    };

    useEffect(() => {
        getTenderList();
    }, []);

    const openImageModal = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const closeImageModal = () => {
        setSelectedImage(null);
        setShowModal(false);
    };

    return (
        <div className="p-4 max-w-7xl mx-auto">
            <form onSubmit={handleSubmit} className="mb-4 space-y-4">
                <div className="flex flex-wrap justify-evenly border">
                    <div className="mb-2 w-full sm:w-[45%] md:w-[30%] ">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="item_name">
                            Item Name
                        </label>
                        <input
                            type="text"
                            name="item_name"
                            value={form.item_name}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="item_name"
                            placeholder="Enter item name"
                        />
                        {errors.item_name && <p className="text-red-500 text-xs italic">{errors.item_name}</p>}
                    </div>
                    <div className="mb-2 w-full sm:w-[45%] md:w-[30%] ">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="item_rate">
                            Item Rate
                        </label>
                        <input
                            type="number"
                            name="item_rate"
                            value={form.item_rate}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="item_rate"
                            placeholder="Enter item rate"
                        />
                        {errors.item_rate && <p className="text-red-500 text-xs italic">{errors.item_rate}</p>}
                    </div>
                    <div className="mb-2 w-full sm:w-[45%] md:w-[30%] ">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="item_quantity">
                            Quantity
                        </label>
                        <input
                            type="number"
                            name="item_quantity"
                            value={form.item_quantity}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="item_quantity"
                            placeholder="Enter quantity"
                        />
                        {errors.item_quantity && <p className="text-red-500 text-xs italic">{errors.item_quantity}</p>}
                    </div>
                    <div className="mb-2 w-full sm:w-[45%] md:w-[30%] ">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                            Description
                        </label>
                        <textarea
                            name="description"
                            value={form.description}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="description"
                            placeholder="Enter description"
                        ></textarea>
                        {errors.description && <p className="text-red-500 text-xs italic">{errors.description}</p>}
                    </div>
                    <div className="mb-4 col-span-2">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="item_image">
                            Upload Images
                        </label>
                        <input
                            type="file"
                            name="item_image"
                            onChange={handleImageChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="item_image"
                            multiple
                        />
                        {errors.item_image && <p className="text-red-500 text-xs italic">{errors.item_image}</p>}
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Add Item
                    </button>
                </div>
            </form>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden">
                    <thead>
                        <tr className="bg-gray-200 text-left text-sm">
                            <th className="py-2 px-4 border-b">Item Name</th>
                            <th className="py-2 px-4 border-b">Item Rate</th>
                            <th className="py-2 px-4 border-b">Description</th>
                            <th className="py-2 px-4 border-b">Quantity</th>
                            <th className="py-2 px-4 border-b">Images</th>
                            <th className="py-2 px-4 border-b">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td className="py-2 px-4 border-b text-center">{item.item_name}</td>
                                <td className="py-2 px-4 border-b text-center">{item.item_rate}</td>
                                <td className="py-2 px-4 border-b text-center">{item.description}</td>
                                <td className="py-2 px-4 border-b text-center">{item.item_quantity}</td>
                                <td className="py-2 px-4 border-b text-center">
                                    {item.item_image && item.item_image.map((image, i) => (
                                        <img
                                            key={i}
                                            src={`${imgURl}${image}`}
                                            alt={`Item ${index} image ${i}`}
                                            className="w-16 h-16 object-cover inline-block mr-2 cursor-pointer"
                                            onClick={() => openImageModal(`${imgURl}${image}`)}
                                        />
                                    ))}
                                </td>
                                <td className="py-2 px-4 border-b text-center">
                                    <button
                                        onClick={() => handleDelete(item._id)}
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal for Image Viewing */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50" onClick={closeImageModal}>
                    <img
                        src={selectedImage}
                        alt="Full-view"
                        className="max-w-full max-h-full object-contain"
                        onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking on the image
                    />
                </div>
            )}
        </div>
    );
};

export default TenderItemForm;
