import React, { useEffect, useState } from 'react';
import api from '../Axios/api';

const VendorForm = () => {
    const [vendors, setVendors] = useState([]);
    const [form, setForm] = useState({
        vendor_name: '',
        vendor_mobile: '',
        vendor_email: '',
        vendor_address: '',
        vendor_area: '',
        vendor_city: '',
        vendor_gstno: '',
        vendor_password: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.vendor_name = form.vendor_name ? "" : "Vendor Name is required.";
        tempErrors.vendor_mobile = form.vendor_mobile ? "" : "Vendor Mobile is required.";
        tempErrors.vendor_email = form.vendor_email ? "" : "Vendor Email is required.";
        tempErrors.vendor_address = form.vendor_address ? "" : "Vendor Address is required.";
        tempErrors.vendor_area = form.vendor_area ? "" : "Vendor Area is required.";
        tempErrors.vendor_city = form.vendor_city ? "" : "Vendor City is required.";
        tempErrors.vendor_gstno = form.vendor_gstno ? "" : "Vendor GST Number is required.";
        tempErrors.vendor_password = form.vendor_password ? "" : "Vendor Password is required.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (validate()) {
                const res = await api.post('vendor/add', form);
                console.log(res.data);
                setForm({
                    vendor_name: '',
                    vendor_mobile: '',
                    vendor_email: '',
                    vendor_address: '',
                    vendor_area: '',
                    vendor_city: '',
                    vendor_gstno: '',
                    vendor_password: ''
                });
                setErrors({});
                getVendorList();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await api.delete(`vendor/delete/${id}`);
            getVendorList();
        } catch (error) {
            console.log(error);
        }
    };

    const getVendorList = async () => {
        try {
            const res = await api.get('vendor/get');
            setVendors(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getVendorList();
    }, []);

    return (
        <div className="p-4 max-w-4xl mx-auto">
            <form onSubmit={handleSubmit} className="mb-4">
                <div className="mb-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="vendor_name">
                        Vendor Name
                    </label>
                    <input
                        type="text"
                        name="vendor_name"
                        value={form.vendor_name}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vendor_name"
                        placeholder="Enter vendor name"
                    />
                    {errors.vendor_name && <p className="text-red-500 text-xs italic">{errors.vendor_name}</p>}
                </div>
                <div className="mb-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="vendor_mobile">
                        Vendor Mobile
                    </label>
                    <input
                        type="text"
                        name="vendor_mobile"
                        value={form.vendor_mobile}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vendor_mobile"
                        placeholder="Enter vendor mobile"
                    />
                    {errors.vendor_mobile && <p className="text-red-500 text-xs italic">{errors.vendor_mobile}</p>}
                </div>
                <div className="mb-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="vendor_email">
                        Vendor Email
                    </label>
                    <input
                        type="email"
                        name="vendor_email"
                        value={form.vendor_email}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vendor_email"
                        placeholder="Enter vendor email"
                    />
                    {errors.vendor_email && <p className="text-red-500 text-xs italic">{errors.vendor_email}</p>}
                </div>
                <div className="mb-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="vendor_address">
                        Vendor Address
                    </label>
                    <textarea
                        name="vendor_address"
                        value={form.vendor_address}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vendor_address"
                        placeholder="Enter vendor address"
                    ></textarea>
                    {errors.vendor_address && <p className="text-red-500 text-xs italic">{errors.vendor_address}</p>}
                </div>
                <div className="mb-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="vendor_area">
                        Vendor Area
                    </label>
                    <input
                        type="text"
                        name="vendor_area"
                        value={form.vendor_area}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vendor_area"
                        placeholder="Enter vendor area"
                    />
                    {errors.vendor_area && <p className="text-red-500 text-xs italic">{errors.vendor_area}</p>}
                </div>
                <div className="mb-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="vendor_city">
                        Vendor City
                    </label>
                    <input
                        type="text"
                        name="vendor_city"
                        value={form.vendor_city}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vendor_city"
                        placeholder="Enter vendor city"
                    />
                    {errors.vendor_city && <p className="text-red-500 text-xs italic">{errors.vendor_city}</p>}
                </div>
                <div className="mb-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="vendor_gstno">
                        Vendor GST Number
                    </label>
                    <input
                        type="text"
                        name="vendor_gstno"
                        value={form.vendor_gstno}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vendor_gstno"
                        placeholder="Enter vendor GST number"
                    />
                    {errors.vendor_gstno && <p className="text-red-500 text-xs italic">{errors.vendor_gstno}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="vendor_password">
                        Vendor Password
                    </label>
                    <input
                        type="password"
                        name="vendor_password"
                        value={form.vendor_password}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vendor_password"
                        placeholder="Enter vendor password"
                    />
                    {errors.vendor_password && <p className="text-red-500 text-xs italic">{errors.vendor_password}</p>}
                </div>
                <div className="flex">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2"
                    >
                        Add Vendor
                    </button>
                </div>
            </form>

            <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="py-2 px-4 border-b">Vendor Name</th>
                        <th className="py-2 px-4 border-b">Vendor Mobile</th>
                        <th className="py-2 px-4 border-b">Vendor Email</th>
                        <th className="py-2 px-4 border-b">Vendor Address</th>
                        <th className="py-2 px-4 border-b">Vendor Area</th>
                        <th className="py-2 px-4 border-b">Vendor City</th>
                        <th className="py-2 px-4 border-b">Vendor GST Number</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {vendors.map((vendor, index) => (
                        <tr key={index}>
                            <td className="py-2 px-4 border-b text-center">{vendor.vendor_name}</td>
                            <td className="py-2 px-4 border-b text-center">{vendor.vendor_mobile}</td>
                            <td className="py-2 px-4 border-b text-center">{vendor.vendor_email}</td>
                            <td className="py-2 px-4 border-b text-center">{vendor.vendor_address}</td>
                            <td className="py-2 px-4 border-b text-center">{vendor.vendor_area}</td>
                            <td className="py-2 px-4 border-b text-center">{vendor.vendor_city}</td>
                            <td className="py-2 px-4 border-b text-center">{vendor.vendor_gstno}</td>
                            <td className="py-2 px-4 border-b text-center">
                                <button
                                    onClick={() => handleDelete(vendor._id)}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default VendorForm;
