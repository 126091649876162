import React from "react";
import TopNav from "./components/common/TopNav";
import { Routes, Route } from "react-router-dom";
import Home from './components/Home';
import Login from "./components/common/Login";
import AuthRouter from "./components/AuthRouter";
import { useSelector } from "react-redux";
import Tender from "./components/Tender";
import TenderItemForm from "./components/TenderItemForm";
import VendorForm from "./components/redux/VendorForm";


const App = () => {

  const currentUser = useSelector((state) => state.user.vendor_name)

  return (
    <div className="flex flex-1 flex-col h-screen w-full overflow-y-scroll main-content">
      {currentUser && <TopNav />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<AuthRouter />}>
          <Route index element={<Home />} />
          <Route path="/tender" element={<Tender />} />
          <Route path="/ItemForm" element={<TenderItemForm />} />
          {/* not confirm */}
          {/* <Route path="/VendorForm" element={<VendorForm />} /> */}
        </Route>
      </Routes>
    </div>

  );
};

export default App;
