import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LayoutDashboard, Activity, Library, LogOut } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/userSlice';
import Cookies from "js-cookie";

const TopNav = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.vendor_name);
    const accepted = useSelector(state => state.user.accepted);
    const logOut = () => {
        Cookies.remove("access-token");
        dispatch(logout());
    }


    return (
        <div className='flex flex-col w-full mx-auto px-5'>
            <div className='flex-1'>
                <div className='p-3 bg-[#fdfdfd] flex items-center border shadow-md justify-between rounded-lg mt-4'>
                    <div className='flex justify-between w-full items-center'>
                        <h2 className='text-lg md:text-xl font-bold'>
                            PolyBond Tender
                        </h2>
                        <div className='flex space-x-2 md:space-x-4'>
                            {
                                user === "Admin" &&
                                <>
                                    <Link to="/ItemForm" className='flex items-center space-x-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 md:px-4 rounded focus:outline-none focus:shadow-outline'>
                                        <Library className='w-4 h-4 md:w-5 md:h-5' />
                                        <span className='hidden sm:inline'>Item Form</span>
                                    </Link>
                                    {/* not conform  */}
                                    {/* <Link to="/VendorForm" className='flex items-center space-x-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 md:px-4 rounded focus:outline-none focus:shadow-outline'>
                                        <Library className='w-4 h-4 md:w-5 md:h-5' />
                                        <span className='hidden sm:inline'>Vendor Form</span>
                                    </Link> */}
                                </>
                            }
                            {
                                accepted &&
                                <>
                                    {/* <Link to="/" className='flex items-center space-x-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 md:px-4 rounded focus:outline-none focus:shadow-outline'>
                                        <LayoutDashboard className='w-4 h-4 md:w-5 md:h-5' />
                                        <span className='hidden sm:inline'>Dashboard</span>
                                    </Link> */}
                                    <Link to={'/tender'} className='flex items-center space-x-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 md:px-4 rounded focus:outline-none focus:shadow-outline'>
                                        <Activity className='w-4 h-4 md:w-5 md:h-5' />
                                        <span className='hidden sm:inline'>Tender</span>
                                    </Link>
                                    <Link onClick={() => { logOut() }} className='flex items-center space-x-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 md:px-4 rounded focus:outline-none focus:shadow-outline'>
                                        <LogOut className='w-4 h-4 md:w-5 md:h-5' />
                                        <span className='hidden sm:inline'>logout</span>
                                    </Link>
                                </>
                            }



                        </div>
                        <div className='text-lg text-blue-500'>
                            {user}
                        </div>
                        <img
                            className="inline-block h-8 w-8 md:h-9 md:w-9 rounded-full ring-2 ring-white"
                            // src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt="User Avatar"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopNav;
