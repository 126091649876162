import React, { useEffect, useState } from 'react';
import { socket } from '../socket';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Tender = () => {
    const imgURl = process.env.REACT_APP_API_IMG;
    const [items, setItems] = useState([]);
    const [inputData, setInputData] = useState({});
    const vendor_id = useSelector((state) => state.user._id);
    const [history, setHistory] = useState([]);
    const [liveCount, setLiveCount] = useState();
    const user = useSelector(state => state.user.vendor_name);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [count, setCount] = useState();
    const [timer, setTimer] = useState();
    const [increaseAmount, setIncreaseAmount] = useState(0);

    useEffect(() => {
        socket.connect();
        socket.on('getTenderItems', (data) => {
            setItems(data);
        });
        socket.on('getAuction', (data) => {
            setHistory(data);
        });

        socket.on('auctionCountdown', (data) => {
            setCount(data);
        });

        socket.on('userConnections', (data) => {
            setLiveCount(data);
        });

        return () => {
            socket.disconnect();
            socket.off('getTenderItems');
            socket.off('getAuction');
            socket.off('userConnections');
        };
    }, []);

    const countDown = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        setTimer(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
    };

    useEffect(() => {
        countDown(count);
    }, [count]);

    const handleStart = () => {
        socket.emit('startActionCountdown');
    };

    const handleStop = () => {
        socket.emit('stopActionCountdown');
    };

    const reset = () => {

        socket.emit('resetActionCountdown')
    }

    const handleIncrease = () => {
        socket.emit('increaseActionCountdown', increaseAmount, (response) => {
            // console.log(response);
        });
    };

    const handleInputChange = (index, value) => {
        setInputData({
            ...inputData,
            [index]: value,
        });
    };

    const handleSubmit = (index, id) => {
        const rate = inputData[index];
        socket.emit('addAuction', { tender_item_id: id, auction_rate: rate, vendor_id }, (data) => {
            setInputData({});
        });
    };

    const openImageModal = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const closeImageModal = () => {
        setSelectedImage(null);
        setShowModal(false);
    };

    return (
        <div className='w-full max-w-6xl mx-auto mt-6 bg-gray-100 rounded-md shadow-md p-6'>

            <h1 className='text-center text-3xl font-bold text-gray-700 mb-6'>
                Tender List <span className={`${count < 3600 ? "text-[#FF7777]" : "text-[#059212]"}`}>
                    Timer - {timer}
                </span>
            </h1>


            {
                user === "Admin" &&
                <div className='flex w-[90%] flex-wrap justify-between mb-4'>
                    <button
                        onClick={handleStart}
                        className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                    >
                        Start
                    </button>
                    <button
                        onClick={handleStop}
                        className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                    >
                        Stop
                    </button>
                    <button
                        onClick={reset}
                        className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                    >
                        reset
                    </button>
                    <div className='flex items-center'>
                        <select
                            value={increaseAmount}
                            onChange={(e) => setIncreaseAmount(e.target.value)}
                            className='shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2'
                        >
                            <option value={0}>Select Time</option>
                            <option value={60}>1 minute</option>
                            <option value={60 * 5}>5 minute</option>
                            <option value={60 * 15}>15 minute</option>
                            <option value={60 * 30}>30 minute</option>
                            <option value={60 * 60}>1 hour</option>
                            <option value={60 * 60 * 12}>12 hour</option>
                            <option value={60 * 60 * 24}>24 hour</option>
                        </select>
                        <button
                            onClick={handleIncrease}
                            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                        >
                            Increase
                        </button>
                    </div>
                </div>
            }

            {items.map((item, index) => (
                <div
                    key={index}
                    className='flex flex-col md:flex-row justify-between items-center p-4 mb-4 bg-white shadow rounded-md'
                >
                    <div className='flex-1 px-4 font-semibold text-gray-600 mb-2 md:mb-0 '>
                        {item.item_name}
                    </div>
                    <div className='flex-1   font-semibold text-gray-600 mb-2 md:mb-0 '>
                        {item.description}
                    </div>
                    <div className='flex-1 md:ml-10 font-semibold text-gray-600 mb-2 md:mb-0'>
                        ₹{item.item_rate}
                    </div>
                    <div className='flex-1  font-semibold text-gray-600 mb-2 md:mb-0'>
                        Qty: {item.item_quantity}
                    </div>
                    <div className='flex-1 text-lg flex max-h-24 border-2 overflow-auto  font-semibold text-gray-600 mb-2 md:mb-0'>
                        {item.item_image && item.item_image.map((image, i) => (
                            <img
                                key={i}
                                src={`${imgURl}${image}`}
                                alt={`Item ${index} image ${i}`}
                                onClick={() => openImageModal(`${imgURl}${image}`)}
                                className="w-16 h-16 object-cover inline-block mr-2"
                            />
                        ))}
                    </div>
                    <div className='flex-1 md:mb-0'>
                        {
                            count != 0 &&
                            <input
                                type='text'
                                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                placeholder='Enter rate'
                                value={inputData[index] || ''}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                            />
                        }
                    </div>
                    {
                        count != 0 &&
                        <div
                            onClick={() => handleSubmit(index, item._id)}
                            className='cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                        >
                            Submit
                        </div>

                    }
                </div>
            ))}
            {/* Modal for Image Viewing */}
            {showModal && selectedImage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50" onClick={closeImageModal}>
                    <img
                        src={selectedImage}
                        alt="Full-view"
                        className="max-w-full max-h-full object-contain"
                        onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking on the image
                    />
                </div>
            )}
            {
                user === "Admin" &&
                <div className='mt-8'>
                    <h2 className='text-2xl font-semibold text-gray-700 mb-4'>
                        Previous Auction Amount&nbsp;
                        <span className='text-[#E4003A]'>
                            : live - {liveCount}
                        </span>
                    </h2>
                    <div className='max-h-[250px] overflow-y-scroll'>
                        {history.map((data, index) => (
                            <div
                                key={index}
                                className='bg-white shadow p-4 mb-4 rounded-md'
                            >
                                <div className='text-lg text-gray-600'>
                                    Item: {data.tender_item_name}
                                </div>
                                <div className='text-lg text-gray-600'>
                                    Vendor Name: {data.vender_name}
                                </div>
                                <div className='text-lg text-gray-600'>
                                    Previous Auction Amount: ₹{data.auction_rate}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
};

export default Tender;
