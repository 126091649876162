import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './Axios/api';
import { useDispatch, useSelector } from 'react-redux';
import { accept_terms } from './redux/userSlice';

const Home = () => {
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.vendor_name);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleAccept = () => {
        if (isChecked) {
            dispatch(accept_terms())
            navigate('/tender');
        } else {
            alert('Please accept the terms and conditions to proceed.');
        }
    };

    useEffect(() => {
        if (user === "Admin") {
            navigate('/tender');
            dispatch(accept_terms())
        }
    }, [])

    return (
        <div className='flex mt-5 w-full justify-center items-center'>
            <div className='flex justify-center w-[90%] md:w-[75%] mx-auto bg-slate-200 rounded-sm shadow-md'>
                <div className='flex flex-col w-full p-6'>
                    <h2 className='text-xl font-bold text-center mb-4'>Terms & Conditions</h2>
                    <div className='text-left mb-4'>
                        <p>1.  Payment 100% advance on actual weight basis.</p>
                        <p>2.  Lifting time with in a 48 hours from the date of final price.</p>
                        <p>3.  Loading buyers scope.</p>
                        <p>4.  Transportation buyers scope.</p>
                    </div>
                    <div className='flex items-center mb-4'>
                        <input
                            type='checkbox'
                            id='acceptTerms'
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            className='mr-2'
                        />
                        <label htmlFor='acceptTerms' className='text-gray-700'>
                            I confirm that I have read and accept the terms and conditions.
                        </label>
                    </div>
                    <div className='flex justify-end'>
                        <button
                            onClick={handleAccept}
                            className='cursor-pointer p-2 bg-[#6091a8] text-white rounded-md hover:bg-[#67b6db] transition-colors'
                        >
                            Accept
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
