import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    _id: null,
    vendor_name: null,
    accepted: false,
}

export const userSlice = createSlice({
    name: 'vendor',
    initialState,
    reducers: {
        loginStart: (state) => {
            state.loading = true;
        },
        loginSuccess: (state, action) => {
            state.loading = false;
            state._id = action.payload._id
            state.vendor_name = action.payload.vendor_name
        },
        accept_terms: (state) => {
            state.accepted = true;
        },
        loginFailure: (state) => {
            state.loading = false;
            state.error = true;
        },
        logout: (state) => {
            state._id = null
            state.vendor_name = null
            state.accepted = false
        }
    }
});

export const { loginStart, loginSuccess, accept_terms, loginFailure, logout, subscription } =
    userSlice.actions;

export default userSlice.reducer;